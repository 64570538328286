import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"

const copy = {
  en: {
    question: "Are you coming from Hong Kong?",
    welcome: "Welcome to",
    canada: "Canada",
    connect: "Connecting Locally",
    information: "Information for New Arrivals",
    welcome_to_canada_title: "Welcome to Canada",
    welcome_to_canada_body_1:
      "• We are looking forward to helping you get settled in our country. Arriving in a foreign land has many challenges. On the Information for Newcomers page, you will find resources and information to help you get settled wherever you are planning to live.",
    welcome_to_canada_body_2:
      "• It can also be hard to meet new friends in your new home. On the Connecting Locally page, you will find information about local communities that have offered to help you settle in to their neighbourhood.",
    next_steps_title: "Next Steps",
    check_out_subtitle: "Check out our Information for Newcomers page",
    check_out_body_1: "• Select a topic like Housing, Education or Health.",
    check_out_body_2:
      "• Watch the short, helpful videos with some basic information.",
    check_out_body_3:
      "• For more information, review the different links with further information on the topic.",
    connect_subtitle:
      "Connect with a local friend through our “Connecting Locally” page.",
    connect_body_1:
      "• Search for the city or location in which you are interested.",
    connect_body_2:
      "• Check out local churches and communities that have registered to welcome newcomers.",
    connect_body_3:
      "• Connect with these communities by clicking on the “Contact” button and filling out the Contact information card.",
    connect_body_4:
      "• Someone from that community will be in touch to welcome you to their community.",
  },
  cn: {
    question: "從香港來嗎?",
    welcome: "歡迎來到",
    canada: "加拿大",
    connect: "本地聯絡",
    information: "新移民資訊",
    welcome_to_canada_title: "歡迎您！",
    welcome_to_canada_body_1:
      "• 歡迎您來到加拿大！我們知道移居到外國會遇到許多挑戰，所以期待能幫助您快快安頓下來，您可以透過「新來加資訊」頁面找到相關資源和資訊去幫助您適應新的生活。",
    welcome_to_canada_body_2:
      "• 另外，在您居住的地方結識新朋友也不是易事，您可以在「本地連結」頁面上找到有關願意幫助您的當地社區群體的資訊。",
    next_steps_title: "下一步",
    check_out_subtitle: "查看我們為「新來加朋友」資訊的頁面",
    check_out_body_1: "• 選擇一個主題，如房屋、教育或醫療。",
    check_out_body_2: "• 觀看一段簡短而實用的短片。",
    check_out_body_3: "• 想得到更多資訊，可以查看包含該主題的不同連接。",
    connect_subtitle: "透過我們的「本地連接」頁面與當地朋友聯繫",
    connect_body_1: "• 搜索您感興趣的城市或地點。",
    connect_body_2: "• 查看已註冊成為「歡迎新來加朋友」的當地教會及社區。",
    connect_body_3:
      "• 按下「聯絡」按鈕，並填寫「聯絡卡」與這些教會或社區聯繫。",
    connect_body_4: "• 來自該教會或社區的人將與您聯絡，歡迎您加入他們的社區。",
  },
}

const Home = ({ lang }) => {
  const translate = key => copy[lang][key]
  const isChinese = lang === "cn"

  return (
    <Layout lang={lang}>
      <section className="welcome__banner">
        <h1 className="header__question">{translate("question")}</h1>
        <h1 className="header__welcome">
          {translate("welcome")}{" "}
          <mark className="header__country">{translate("canada")}!</mark>
        </h1>
        <Link
          className="btn btn--primary header__button"
          to={isChinese ? "/cn/connect/" : "/connect/"}
        >
          {translate("connect")}&nbsp;→
        </Link>
        <Link
          className="btn btn--primary header__button"
          to={isChinese ? "/cn/information/" : "/information/"}
        >
          {translate("information")}&nbsp;→
        </Link>
      </section>
      <div className="family__picture" />
      <section className="welcome__section">
        <div className="welcome__blurb">
          <h4 className="welcome__blurb-title">
            {translate("welcome_to_canada_title")}
          </h4>
          <p>{translate("welcome_to_canada_body_1")}</p>
          <p>{translate("welcome_to_canada_body_2")}</p>
        </div>
        <div className="welcome__blurb">
          <h4 className="welcome__blurb-title">
            {translate("next_steps_title")}
          </h4>
          <h5 className="welcome__blurb-subtitle">
            {translate("check_out_subtitle")}
          </h5>
          <p>
            {translate("check_out_body_1")}
            <br />
            {translate("check_out_body_2")}
            <br />
            {translate("check_out_body_3")}
          </p>
          <h5 className="welcome__blurb-subtitle">
            {translate("connect_subtitle")}
          </h5>
          <p>
            {translate("connect_body_1")}
            <br />
            {translate("connect_body_2")}
            <br />
            {translate("connect_body_3")}
            <br />
            {translate("connect_body_4")}
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default Home
